import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TRemoteConfig} from './type';

const initialState: TRemoteConfig = {
  lastUpdateTime: undefined,
  rawRemoteData: undefined,
  tnowCategoryLink: [],
  searchMainCategoryLink: [],
  searchResultCategoryLink: [],
  customCategoryPage: [],
  themeMarker: [],
  tnowPopupVersion: undefined,
  tnowPopupList: [],
  evFilterList: [],
  searchRecommendKeyword: [],
  tplaceContentsList: [],
  tooltipList: [],
};

const remoteSlice = createSlice({
  name: 'remote',
  initialState,
  reducers: {
    setRawConfig: (state, {payload}: PayloadAction<Record<string, any>>) => {
      state.rawRemoteData = {
        ...(state.rawRemoteData || {}),
        ...payload,
      };
    },
    setRemoteConfig: (state, {payload}: PayloadAction<TRemoteConfig>) => {
      state.lastUpdateTime = Date.now();
      state.tnowCategoryLink = payload.tnowCategoryLink;
      state.searchMainCategoryLink = payload.searchMainCategoryLink;
      state.searchResultCategoryLink = payload.searchResultCategoryLink;
      state.customCategoryPage = payload.customCategoryPage;
      state.themeMarker = payload.themeMarker;
      state.tnowPopupVersion = payload.tnowPopupVersion;
      state.tnowPopupList = payload.tnowPopupList;
      state.evFilterList = payload.evFilterList;
      state.searchRecommendKeyword = payload.searchRecommendKeyword;
      state.tplaceContentsList = payload.tplaceContentsList;
      state.tooltipList = payload.tooltipList;
    },
  },
});

export default remoteSlice;
