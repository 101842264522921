import {useEffect, useMemo, useState} from 'react';
import {useAppSelector} from 'ducks/hooks';

import classNames from 'classnames';

import s from '../../styles/pages/SearchMainPage.module.scss';

// TODO: firebase tooltip list 추가되면 이동
export enum ETooltipType {
  SEARCH_MAIN_RECOMMEND = 'SEARCH_MAIN_RECOMMEND',
}

const EXPOSE_TIME = 4000;

type TProps = {
  active: boolean;
};

const SearchMainTabCoachMark = ({active}: TProps) => {
  const {tooltipList} = useAppSelector((state) => state.remote);
  const hasSearchMainTabTooltip = useMemo(
    () => tooltipList.some((tooltip) => tooltip.type === ETooltipType.SEARCH_MAIN_RECOMMEND),
    [tooltipList]
  );
  const [isActive, setIsActive] = useState(hasSearchMainTabTooltip);

  useEffect(() => {
    setIsActive(hasSearchMainTabTooltip);
  }, [hasSearchMainTabTooltip]);

  useEffect(() => {
    if (!hasSearchMainTabTooltip) {
      return;
    }

    setTimeout(() => {
      setIsActive(false);
    }, EXPOSE_TIME);
  }, [hasSearchMainTabTooltip]);

  useEffect(() => {
    if (active) {
      return;
    }

    setIsActive(false);
  }, [active]);

  return (
    <>
      {hasSearchMainTabTooltip && (
        <p
          className={classNames(s.coach_mark, {
            [s.active]: isActive,
          })}
        >
          <object
            data={require('../../resource/images/search_recommend_coach_mark.svg').default}
            aria-label={'새로운 추천탭이 생겼어요'}
          />
        </p>
      )}
    </>
  );
};

export default SearchMainTabCoachMark;
