import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import ContentWrap from '../shared/ContentWrap';
import actions from 'ducks/actions';
import {ECurationViewType} from 'ducks/remote/type';
import {fetchAroundMeList} from 'ducks/tplacehome/slice';
import {EAdditionalInfo, ETPlaceTab} from 'ducks/tplacehome/types';
import {debounce, isEmpty} from 'utils/lodash';
import {useOnce} from 'hooks/useOnce';
import InView from 'react-intersection-observer';
import AroundMeItem from './AroundMeItem';

import {EBusinessHourStatus} from 'types/App';
import AroundMeHeader from './AroundMeHeader';
import ContentError from '../shared/ContentError';
import AroundMeEmpty from './AroundMeEmpty';
import AroundMeSkeleton from './AroundMeSkeleton';

import s from 'styles/components/tplacehome/TPlaceAroundMe.module.scss';
import DiscoveryRegionList from '../discovery/DiscoveryRegionList';
import RankingStaticBanner from '../shared/RankingStaticBanner';
import TPlaceCuration from '../TPlaceCuration';
import {
  EAdCode,
  PLACE_BANNER_AD_STEP,
  PLACE_BANNER_PROVIDER_CONFIG,
  PLACE_BANNER_SECOND_PROVIDER_CONFIG,
} from 'constant/Ads';
import usePlaceHome from 'hooks/usePlaceHome';
import cloneDeep from 'lodash/cloneDeep';
import TPlaceAdBanner from '../shared/TPlaceAdBanner';

const AD_BANNER_FIRST_INDEX = 0;
const AD_BANNER_SECOND_INDEX = 19;

const AroundMe = () => {
  const {tplacehome, userInteraction, remote} = useAppSelector((state) => state);
  const filteredList = useMemo(
    () => tplacehome.aroundMeData.result.data.filteredList,
    [tplacehome.aroundMeData.result.data.filteredList]
  );
  const firstDataLoaded = useRef(false);
  const dispatch = useAppDispatch();
  const placehomeHook = usePlaceHome();

  const [contentScrollTop, setContentScrollTop] = useState(-1);
  const [contentScrollTopKey, setContentScrollTopKey] = useState(-1);

  const curationMap = useMemo(
    () =>
      remote.tplaceContentsList
        .filter((v) => v.viewType === ECurationViewType.AROUND)
        .reduce(
          (obj, v) => ({
            ...obj,
            [v.order]: [...(obj[v.order] || []), v],
          }),
          {}
        ),
    [remote.tplaceContentsList]
  );

  /**
   * 콘텐츠 스크롤에 다른 activePoi 지정 (지도 마커 활성화)
   */
  const visibleItemList = useRef<(boolean | string)[]>([]);
  const activeId = useMemo(() => {
    if (userInteraction.activePoi && ['marker', 'rotate'].includes(userInteraction.trigger || '')) {
      return userInteraction.activePoi;
    }
    return undefined;
  }, [userInteraction]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateFocusItem = useCallback(
    debounce(() => {
      const idx = visibleItemList.current.findIndex((visible) => visible === true);
      const item = tplacehome.aroundMeData.result.data.filteredList[idx];
      if (item) {
        dispatch(
          actions.userInteraction.setInteraction({
            activePoi: item.listId,
            trigger: 'scroll',
          })
        );
      }
    }, 300),
    [tplacehome.aroundMeData.result.data.filteredList]
  );
  const onChangeViewingItems = useCallback(
    (i, visible) => {
      visibleItemList.current[i] = visible;
      updateFocusItem();
    },
    [updateFocusItem]
  );

  /**
   * 지도에서 마커 탭시 콘텐츠 스크롤
   */
  const moveContentScroll = useCallback((value: number) => {
    setContentScrollTop(value);
    setContentScrollTopKey(Date.now());
  }, []);
  useEffect(() => {
    if (activeId) {
      const position =
        document.querySelector<HTMLDivElement>(`[data-id="${activeId}"]`)?.offsetTop || 0;
      moveContentScroll(position);
    }
  }, [activeId, moveContentScroll]);
  useEffect(() => {
    moveContentScroll(0);
  }, [moveContentScroll, tplacehome.aroundMeData.result.data.list]);

  /**
   * 새로 데이터를 불러오거나, 필터가 선택됐을때 실제 보여지는 리스트 갱신 (filteredList)
   */
  useEffect(() => {
    const {additionalInfos} = tplacehome.aroundMeData.selectedFilters;
    const sortByDistance = tplacehome.aroundMeData.sortByDistance;
    let result = cloneDeep(tplacehome.aroundMeData.result.data.list);
    if (!result) {
      return;
    }

    const hasFilters = !isEmpty(additionalInfos);

    if (hasFilters) {
      result = result.filter((item) => {
        // 부가정보 - 하나라도 해당되지 않으면 열외
        const checkOpened = additionalInfos.includes(EAdditionalInfo.OPENED);
        const checkReservation =
          additionalInfos.includes(EAdditionalInfo.RESERVATION) &&
          placehomeHook.isReservationAvailableRegion;
        const checkParking = additionalInfos.includes(EAdditionalInfo.PARKING);

        if (checkOpened && item.special.businessHourStatus !== EBusinessHourStatus.OPEN) {
          return false;
        }
        if (
          checkReservation &&
          !(
            item.special.catchTableWaitingResponse &&
            (item.tag.isTmapReservation || item.tag.isTmapWaiting)
          )
        ) {
          return false;
        }
        if (checkParking && !(item.tag.isParking || item.tag.isValetParking)) {
          return false;
        }

        return true;
      });
    }

    if (sortByDistance) {
      // readonly?
      result.sort((a, b) => {
        if (a.distance < b.distance) {
          return -1;
        } else {
          return 1;
        }
      });
    }

    dispatch(actions.tplacehome.setAroundMeFilteredList(result));
  }, [
    dispatch,
    tplacehome.aroundMeData.result.data.list,
    tplacehome.aroundMeData.selectedFilters,
    tplacehome.aroundMeData.sortByDistance,
    placehomeHook.isReservationAvailableRegion,
  ]);

  /**
   * initial load
   */
  useOnce(
    // tplacehome.initialDataLoaded && tplacehome.currentTab === ETPlaceTab.AROUND_ME,
    tplacehome.initialDataLoaded,
    async () => {
      await dispatch(fetchAroundMeList({}));
    }
  );

  /**
   * 선택된 업종 변경시 api 재호출
   * 업종은 featureOr API param으로 필터링
   */
  useEffect(() => {
    if (firstDataLoaded.current) {
      dispatch(fetchAroundMeList({}));
    }
  }, [dispatch, tplacehome.aroundMeData.selectedFilters.categoryGroups]);

  /**
   * logs
   */
  useOnce(tplacehome.aroundMeData.result.loaded, () => {
    firstDataLoaded.current = true;
  });
  useEffect(() => {
    if (firstDataLoaded.current) {
      placehomeHook.sendEventAroundMe('view.around_poi', {
        list_num: tplacehome.aroundMeData.result.data.list.length,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tplacehome.aroundMeData.result.data.list]);

  /********************************************
   * render
   */
  // 아이템 뒤에 붙는 컴포넌트
  const getAfterContentComp = useCallback(
    (index: number) => {
      if (index === AD_BANNER_FIRST_INDEX) {
        return (
          <>
            <SpacerWhite />
            <TPlaceAdBanner
              adOption={PLACE_BANNER_PROVIDER_CONFIG}
              adStep={PLACE_BANNER_AD_STEP}
              adCode={EAdCode.PLACE_AROUND_ME_FIRST}
              isLogActive={tplacehome.currentTab === ETPlaceTab.AROUND_ME}
            />
          </>
        );
      }

      // 지역별 인기장소
      if (index === 10) {
        return (
          <>
            <SpacerWhite />
            <SpacerGray />
            {/* first-of-type 적용되서 추가 */}
            <SpacerWhite height="16px" />
            <InView
              onChange={(isView) => isView && placehomeHook.sendEventAroundMe('view.tmapranking')}
            >
              <DiscoveryRegionList />
            </InView>
            <SpacerGray />
          </>
        );
      }

      if (index === AD_BANNER_SECOND_INDEX) {
        return (
          <>
            <SpacerWhite />
            <TPlaceAdBanner
              adOption={PLACE_BANNER_SECOND_PROVIDER_CONFIG}
              adCode={EAdCode.PLACE_AROUND_ME_SECOND}
              isLogActive={tplacehome.currentTab === ETPlaceTab.AROUND_ME}
            />
          </>
        );
      }

      if (curationMap[index]?.length > 0) {
        return (
          <ul className={s.curation_wrap}>
            {curationMap[index].map((v) => (
              <li key={v.id}>
                <InView
                  onChange={(isView) =>
                    isView &&
                    placehomeHook.sendEventAroundMe('view.curating_contents', {
                      curating_content_type: v.type,
                    })
                  }
                >
                  <SpacerGray />
                  <TPlaceCuration item={v} />
                </InView>
              </li>
            ))}
            <SpacerGray />
          </ul>
        );
      }
    },
    [curationMap, placehomeHook, tplacehome.currentTab]
  );

  // 콘텐츠 컴포넌트
  const BodyComp = useMemo(() => {
    if (tplacehome.aroundMeData.result.error) {
      return (
        <ContentError
          onClickRefresh={() => {
            placehomeHook.sendEventAroundMe('tap.fail_refresh');
            window.location.reload();
          }}
        />
      );
    }
    if (tplacehome.aroundMeData.result.loading) {
      return <AroundMeSkeleton />;
    }
    if (isEmpty(filteredList)) {
      return (
        <>
          <AroundMeEmpty />
          <TPlaceAdBanner
            adOption={PLACE_BANNER_PROVIDER_CONFIG}
            adStep={PLACE_BANNER_AD_STEP}
            adCode={EAdCode.PLACE_AROUND_ME_FIRST}
            isLogActive={tplacehome.currentTab === ETPlaceTab.AROUND_ME}
          />
        </>
      );
    }
    return (
      <>
        <div className={s.content_list_wrap}>
          {filteredList.map((item, index) => (
            <div className={s.content_list_item} key={item.listId}>
              <InView
                as="div"
                data-type="poi"
                threshold={0.5}
                onChange={(visible) => onChangeViewingItems(index, visible)}
              >
                <AroundMeItem item={item} itemIndex={index} />
              </InView>
              {getAfterContentComp(index)}
            </div>
          ))}
        </div>
        {/* 최하단 컨텐츠 */}
        {filteredList.length && filteredList.length < 20 ? (
          <InView
            onChange={(isView) =>
              isView && placehomeHook.sendEventAroundMe('view.tmapranking_banner')
            }
          >
            <RankingStaticBanner />
          </InView>
        ) : (
          <SpacerWhite />
        )}
      </>
    );
  }, [
    filteredList,
    getAfterContentComp,
    onChangeViewingItems,
    placehomeHook,
    tplacehome.aroundMeData.result.error,
    tplacehome.aroundMeData.result.loading,
    tplacehome.currentTab,
  ]);

  if (!tplacehome.initialDataLoaded) {
    return null;
  }
  return (
    <ContentWrap
      contentScrollTop={contentScrollTop}
      contentScrollTopKey={contentScrollTopKey}
      hideBtnTop={tplacehome.aroundMeData.result.loading}
    >
      <AroundMeHeader />
      {BodyComp}
    </ContentWrap>
  );
};

const SpacerGray = () => {
  return <div style={{height: '8px', backgroundColor: '#EEF0F3'}} />;
};

const SpacerWhite = ({height = '24px'}: {height?: string}) => {
  return <div style={{height, backgroundColor: '#FFF'}} />;
};

export default AroundMe;
